@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ssProRegular";
  src:  url(./assets/fonts/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: "ssProBold";
  src:  url(./assets/fonts/SourceSansPro-Bold.ttf);
}

@font-face {
  font-family: "ssProSemiBold";
  src:  url(./assets/fonts/SourceSansPro-SemiBold.ttf);
}


body {
  margin: 0;
  font-family: "ssProRegular";
}

.nav-tabs .nav-link.active {
  color: #1D2433;
  font-weight: 700;
  border-color: unset;
}

.nav-tabs .nav-link {
  color: #4B505B;
  margin: 20px 0px;
}

.sidebar-inner {
  z-index: 10000;
  background: #FFFFFF;
}

.hover__text {
  display: none; 
}

.hover__img:hover .hover__text {
  display: block;
  position: absolute;
  bottom: 12%;
  left: 3%;
  color: #1D2433;
  opacity: 1.0;
  font-size: 32px;
}

.hover__img {
  padding-left: 10px;
  padding-right: 10px;
}

.hover__img:hover img {
  background: #FFFFFF;
  opacity: .3 !important;
}

.carousel-item {
  height: 100vh;
}

.text-process {
  margin-right: 80px;
}

.about-history {
  width: 320px;
  text-align: left;
}

.about-years {
  /* width: 80px; */
  text-align: left;
}

.table-text-normal {
  font-size: 16px;
  font-weight: 400;
  color: #4B505B;
}

.table-text-bold {
  font-size: 20px;
  font-weight: 600;
  color: #4B505B;
}

/* .hover__img img:hover {
  background: #FFFFFF;
  opacity: .3 !important;
} */


/* .lg\:w-6\/12 {
  width: 45% !important;
} */

.hover__text-carousel {
  display: none; 
}

.detail-project.mobiles .carousel-item img {
  height: 100vh;
}

.container-history-desktop {
  display: block;
}

.container-history-mobile {
  display: none;
}


.hover__img-carousel:hover .hover__text-carousel {
  display: block;
  position: absolute;
  bottom: 5%;
  left: 3%;
  font-size: 32px;
  color: #1D2433;
  opacity: 1.0;
}

.hover__img-carousel:hover img {
  background: #FFFFFF;
  opacity: .3 !important;
}

.carousel-home-1 {
  /* background-image: url("https://live.staticflickr.com/65535/52874618019_b047dad894_k.jpg"); */
  background-image: url("./assets/001.jpg");
  padding-bottom: 150%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.carousel-home-2 {
  /* background-image: url("https://live.staticflickr.com/65535/52874828230_2ba9d9ae5b_k.jpg"); */
  background-image: url("./assets/002.jpg");
  padding-bottom: 150%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.carousel-home-3 {
  /* background-image: url("https://live.staticflickr.com/65535/52662713509_8089614085_k.jpg"); */
  background-image: url("./assets/003.jpg");
  padding-bottom: 150%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.carousel-home-4 {
  /* background-image: url("https://live.staticflickr.com/65535/52662867295_a1532bcb95_k.jpg"); */
  background-image: url("./assets/004.jpg");
  padding-bottom: 150%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.carousel-home-5 {
  /* background-image: url("https://live.staticflickr.com/65535/52662921558_2c226946e5_k.jpg"); */
  background-image: url("./assets/005.JPG");
  padding-bottom: 150%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.carousel-home-6 {
  /* background-image: url("https://live.staticflickr.com/65535/52662713539_7654bd9204_k.jpg"); */
  background-image: url("./assets/006.jpg");
  padding-bottom: 150%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.carousel-control-prev-icon {
  background-image: url("./assets/arrow-left-black.svg");
  background-color: rgba(255, 255, 255, 0.48);
  border-radius: 24px;
}

.carousel-control-next-icon {
  background-image: url("./assets/arrow-right-black.svg");
  background-color: rgba(255, 255, 255, 0.48);
  border-radius: 24px;
}

.projects-page-1 {
  background-image: url("https://live.staticflickr.com/65535/52661926102_c7ecb1f892_k.jpg");
  padding-bottom: 150%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-about {
  font-size: 24px;
  color: #4B505B;
  text-transform: uppercase;
  margin-bottom: 5px;
  position: relative;
}

.about-height {
  height: 500px;
  margin-right: 32px;
}

.image-aboutus {
  height: 100vh;
  height: 100svh;
}


.text-about::before {
  position: absolute;
  display:block;
  top:0;
  left:0;
  bottom:0;
  content: attr(data-content);
  color:#E1E6EF;
  z-index:-1;
  padding-right:.5em;
}

.text-about::after {
  position:absolute;
  content:'';
  display: block;
  left:30%;
  right:0;
  top:50%;
  background-color:#E1E6EF;
  height:.1em;
  min-height:1px;
  z-index:-2;
}

/* MOBILE SIDEBAR/MENU */
.header-mobile {
  display: none;
}



@media (max-width: 480px) {
body.header-mobile--is-open {
  overflow: hidden;
}

.image-aboutus {
  height: 476px;
}

.about-height {
  height: auto;
  margin-right: unset;
  margin-top: 24px;
}

.text-about {
  margin-bottom: unset;
}

.text-process {
  margin-right: 10px;
}



.sidebar-wrapper {
  position: fixed;
  background: #fff;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 0;
}

.header-mobile--is-open.sidebar-inner {
  height: 100vh;
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: calc(100% - 80px);
  padding: 1.5rem 2rem;
  position: fixed;
  top: 85px;
  left: 0;
  bottom: 0;
  width: 100%;
  transform: translateX(-101%);
  transition: all .4s ease-in;
}

.header-mobile--is-open .sidebar-menu {
  transform: translateX(0);
}

.sidebar__nav-logo {
  display: none;
}

.sidebar__footer {
  margin-top: auto;
}

.header-mobile {
  display: block;
  padding: 1.2rem 2rem;
}

.header-mobile-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-mobile__icon img {
  height: 40px;
}

.header-mobile--is-open .header-mobile__icon img {
  transform: rotate(90deg);
  transition: transform .5s ease;
}

.content-wrapper {
  padding: 0 1rem 1rem;
  flex-wrap: wrap;
}

.hover__img:hover .hover__text {
  font-size: 24px;
  bottom: 15%;
}

.container-history-desktop {
  display: none;
}

.container-history-mobile {
  display: block;
}

.history-margin {
  margin-top: 24px;
}

.text-about::before {
  position: absolute;
  display:block;
  top:0;
  left:0;
  bottom:0;
  content: attr(data-content);
  color:#E1E6EF;
  z-index:-1;
  padding-right:.5em;
}

.text-about::after {
  position:absolute;
  content:'';
  display: block;
  left:74%;
  right:0;
  top:50%;
  background-color:#E1E6EF;
  height:.1em;
  min-height:1px;
  z-index:-2;
}

}

/* @media screen and (min-width: 1366px) {
  .image-aboutus {
    width: 600px;
  }
}

@media screen and (min-width: 1920px) {
  .image-aboutus {
    width: 800px;
  }
} */